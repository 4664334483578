@import "../styles/variables"; // Importa as variáveis

.listapacotes {
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.listapacotes::-webkit-scrollbar {
  display: none; /* Ocultar a barra de rolagem no Chrome, Safari e Opera */
}

.listapacotes {
  -ms-overflow-style: none; /* Ocultar a barra de rolagem no IE e Edge */
  scrollbar-width: none; /* Ocultar a barra de rolagem no Firefox */
}
