@import "../styles/variables"; // Importa as variáveis

.pacote {
  background-color: white;
  padding: 20px 20px 40px 20px;
  max-width: 90%;
  border-radius: 25px;
  border: 1px solid $line-border;
  display: inline-block; /* Os cartões são exibidos em linha */
  margin-right: 1.5rem; /* Espaçamento entre os cartões */

  .internet {
    gap: 7px;
    padding: 25px 70px;
    background-color: #f5f5f5;
    border-radius: 20px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: black;
      margin: 0px;
    }

    h2 {
      font-size: 36px;
      font-weight: 700;
      color: black;
      margin: 0px;
    }
  }

  .comodidades {
    padding: 1.2rem;
    .list {
      margin: 0.2rem;
    }
  }

  .infoprice {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    gap: 0.8rem;
    margin-bottom: 1rem;

    .apenas {
      font-size: 14px;
      font-weight: 400;
      color: #ff0000;
      line-height: 100%;
      text-align: right;

      p {
        margin: 0;
      }
    }

    .price {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
    }

    .destaqueprice {
      font-size: 26px;
    }
  }

  a {
    font-size: 1rem;
    padding: 15px 30px;
    border-radius: 15px;
    background-color: $primary-color;
    color: white;
    &:hover {
      background-color: $secondary-color;
    }
  }
}
