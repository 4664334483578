#card-ideal {
  background-color: white;
  border: 1px solid #ff0000;
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 15px;
}

#card-ideal #imagem img {
  max-width: 100%;
  border-radius: 20px;
  margin: 0px;
}

#card-ideal #infos {
  text-align: start;
}

#card-ideal #infos h3 {
  margin: 5px 0px;
}
#card-ideal #infos p {
  margin: 0px;
}
