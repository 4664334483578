#diferencial {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 20px;
}

#diferencial #icon {
  padding: 20px;
  border-radius: 20px;
  background-color: brown;
}

#diferencial #text {
  text-align: start;
}

#diferencial #text h3 {
  margin: 4px 0px;
  font-size: 20px;
  font-weight: 700px;
  color: #ff0000;
}

#diferencial #text p {
  margin: 0px;
}
