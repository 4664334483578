* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body a {
  text-decoration: none;
}

body,
button,
input,
select,
option,
textarea {
  font-family: "Roboto", sans-serif;
}

.bodyoficial {
  margin: 0 auto; /* Isso centralizará o conteúdo horizontalmente */
  max-width: 1200px;
  text-align: center;
}

.lista-diferenciais {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 50px;
}
