@import "../styles/variables"; // Importa as variáveis

.container {
  margin-top: 5rem;
  text-align: center;
  width: 100%;

  div:first-child {
    width: 100%;
    background-color: white;
    padding: 2rem;
  }
  div:last-child {
    padding: 2rem;
    background-color: grey;
  }
}
