@import "../styles/variables"; // Importa as variáveis

.principalcontent {
  margin: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  div {
    order: 2;
    max-width: 90%;
    align-items: center;
    text-align: start;
    gap: 10px;

    h1 {
      font-size: 2rem;
      line-height: 100%;
      font-weight: 700;

      span {
        color: $secondary-color;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 130%;
      font-weight: 400;
    }
  }

  img {
    margin-bottom: 1rem;
    align-items: center;
    max-width: 70%;
  }
}
