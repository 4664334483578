/* Navbar.module.scss */

@import "../styles/variables"; // Importa as variáveis

nav {
  padding: 0.5rem 0;
  background-color: white;
  border-bottom: 1px solid $line-border;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  color: #fff;
  max-width: $max-width-desktop;
  margin: 0 auto;
}

.navbarBrand {
  font-size: 24px;
}

.navbarMenu {
  display: flex;
  align-items: center;
}

.navbarMenu a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
}

.navbarMenu a:hover {
  color: $primary-color;
}

.navbarToggle {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbarMenu {
    display: none;
  }

  .navbarMenu.isMobile {
    display: flex;
    flex-direction: column;
    background-color: $background-color;
    position: absolute;
    top: 60px; /* Ajuste conforme necessário para o posicionamento */
    width: 30%;
    border: 1px $line-border solid;
    border-radius: 5px;
    right: 0;
    padding: 10px;
    margin-right: 10px;
  }

  .navbarMenu.isMobile a {
    padding: 10px 0;
  }

  .navbarMenu.isMobile a:hover {
    color: $primary-color;
  }

  .navbarToggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .navbarToggle:hover {
    color: $primary-color;
  }
  .bar {
    width: 100%;
    height: 3px;
    background-color: black;
  }
}
