@import "../styles/variables"; // Importa as variáveis

.botaoVermelho {
  font-size: 20px;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 15px;
  background-color: $primary-color;
  color: white;
  border: none;
  margin: 1rem;

  &:hover {
    background-color: $secondary-color;
  }
}
