.titulo {
  margin: 5rem 10% 2rem 10%;

  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    font-size: 1.2rem;
    font-weight: 200;
  }
}
